<!-- RealEstateGrid.vue -->
<template>
  <div class="container">
    <div class="controls mb-4">
      <button 
        @click="addNewProperty"
        class="btn btn-primary mr-2"
      >
        Add New Property
      </button>
      
      <div 
        class="drop-zone" 
        @dragover.prevent 
        @drop.prevent="handleUrlDrop"
        :class="{ 'drag-over': isDragging }"
        @dragenter.prevent="isDragging = true"
        @dragleave.prevent="isDragging = false"
      >
        <div v-if="isLoading" class="loading-spinner">
          Processing...
        </div>
        <div v-else>
          Drop Property URL Here
        </div>
      </div>
    </div>

    <div v-if="alert" :class="`alert alert-${alert.type}`" role="alert">
      {{ alert.message }}
    </div>

    <div class="grid-container">
      <draggable 
        :value="propertyTiles"
        @input="handleDragInput"
        group="properties"
        class="property-grid"
      >
        <div 
          v-for="tile in propertyTiles" 
          :key="tile.id"
          class="property-tile"
          :style="getTileStyle(tile)"
        >
          <div class="tile-header">
            <h3>{{ tile.formData.property_details?.neighborhood || 'N/A' }}</h3>
            <div>
              <button 
                @click="editTile(tile.id)"
                class="edit-btn"
              >
                <Icon icon="mdi:pencil" />
              </button>
              <button 
                @click="maximizeTile(tile.id)"
                class="maximize-btn"
              >
                <Icon icon="mdi:window-maximize" />
              </button>
              <button 
                @click="deleteTile(tile.id)"
                class="delete-btn"
              >
                <Icon icon="mdi:close" />
              </button>
            </div>
          </div>
          
          <div class="tile-content">
            <p><strong>Property Type:</strong> {{ tile.formData.property_details?.property_type || 'N/A' }}</p>
            <p><strong>Purchase Price:</strong> ${{ formatNumber(tile.formData.property_details?.purchase_price) }}</p>
            <!-- <p><strong>Gross Revenue:</strong> ${{ formatNumber(tile.formData.property_details?.potential_gross_revenue) }}</p>
            <p><strong>Operating Costs:</strong> ${{ formatNumber(tile.formData.property_details?.operating_costs) }}</p> -->
            <p><strong>Beta: </strong> {{ formatBeta(tile.alphaRes?.beta) }} </p>
            <p><strong>Alpha:</strong> {{ formatAlpha(tile.alphaRes?.alpha)}}%</p>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import axios from 'axios'
import { Icon } from '@iconify/vue2';

export default {
  name: 'RealEstateGrid',
  
  components: {
    draggable,
    Icon
  },

  data() {
    return {
      isDragging: false,
      isLoading: false,
      alert: null
    }
  },

  computed: {
    ...mapGetters('properties', ['getAllProperties']),
    
    propertyTiles() {
      return this.getAllProperties
    },
    
    tileWithHighestAlpha() {
      return this.propertyTiles.reduce((maxTile, currentTile) => {
        return (currentTile.alphaRes?.alpha > maxTile.alphaRes?.alpha) ? currentTile : maxTile
      }, this.propertyTiles[0])
    }
  },

  methods: {
    ...mapActions('properties', ['addProperty', 'deleteProperty', 'setIsAddingNew', 'getProperty']),

    handleDragInput(newOrder) {
      // Handle draggable reordering if needed in the future
      console.log('New order:', newOrder)
    },

    formatNumber(value) {
      if (!value || value === 'N/A') return 'N/A'
      return new Intl.NumberFormat().format(value)
    },

    formatAlpha(a) {
      let alpha = Number(a) * 100;
      return isNaN(alpha) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(alpha);
    },

    formatBeta(b) {
      let beta = Number(b);
      return isNaN(beta) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(beta);
    },

    addNewProperty() {
      this.setIsAddingNew(true)
      this.$router.push({
        name: 'CalculateAlpha',
        params: {
          sendBackToGrid: true
        }
      })
    },

    deleteTile(tileId) {
      this.deleteProperty(tileId)
    },

    editTile(tileId) {
      console.log('Editing tile:', tileId)
      const property = this.$store.getters['properties/getPropertyById'](tileId);
      console.log('Editing data:', property)
      this.deleteProperty(tileId)
      this.$router.push({
        name: 'CalculateAlpha',
        params: {
          sendBackToGrid: true,
          initialFormData: property.formData
        }
      })
    },

    maximizeTile(tileId) {
      console.log('Editing tile:', tileId)
      const property = this.$store.getters['properties/getPropertyById'](tileId);
      console.log('Editing data:', property)
      this.$router.push({
        name: 'ShowAlpha',
        params: {
          sendBackToGrid: true,
          alphaResults: property.alphaRes,
          neighborhood: property.formData.property_details.neighborhood,
          formData: property.formData
        }
      })
    },

    showAlert(message, type = 'info') {
      this.alert = { message, type }
      setTimeout(() => {
        this.alert = null
      }, 5000)
    },

    async handleUrlDrop(event) {
      this.isDragging = false
      this.isLoading = true
      const url = event.dataTransfer.getData('text')
      
      try {
        const response = await axios.post('https://alpha.realestatealpha.ai/api/parse_link', { "url":url })
        // const response = await axios.post('http://127.0.0.1:5000/api/parse_link', { "url":url })
        console.log('Parsing request status:', response.status)
        console.log('Parsed property URL:', response.data)
        if (response.status === 200) {
          // this.addProperty({
          //   property_details: {
          //     ...response.data.data
          //   }
          // })
          this.$router.push({
            name: 'CalculateAlpha',
            params: {
              sendBackToGrid: true,
              initialFormData: response.data
            }
          })

          this.showAlert('Property added successfully!', 'success')
        } else {
          throw new Error(response.data.error)
        }
      } catch (error) {
        console.error('Error parsing property URL:', error)
        this.showAlert(
          error.response?.data?.error || 'Unable to parse property URL. Please check the format.',
          'error'
        )
      } finally {
        this.isLoading = false
      }
    },

    getTileStyle(tile) {
      if (tile.id === this.tileWithHighestAlpha.id) {
        return {
          borderColor: 'green',
          boxShadow: '0 4px 8px rgba(0, 128, 0, 0.4)'
        }
      }
      return {}
    }
  }
}
</script>

<style scoped>
.container {
  padding: 20px;
}

.property-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.property-tile {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.edit-btn {
  /* background: none;
  border: none;
  font-size: 20px;
  cursor: pointer; */
  color: #949494;
}

.delete-btn {
  /* background: none;
  border: none;
  font-size: 20px;
  cursor: pointer; */
  color: #ff4444;
}

.drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.drop-zone.drag-over {
  background-color: #f0f0f0;
  border-color: #666;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}
.tile-content{
  text-align: justify;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>