import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Router from 'vue-router';
// import RealEstateAlpha from '../components/RealEstateAlpha.vue';
import CalculatorForm from '../components/CalculatorForm.vue';
import HowTo from '../components/HowTo.vue';
import MethodSummary from '../components/MethodSummary.vue';
// import MontrealMap from '../components/MontrealMapRent.vue';
import MontrealMap from '../components/MontrealMap.vue';
import ShowAlpha from '../components/ShowAlpha.vue';
import RealEstateGrid from '../components/RealEstateGrid.vue';
import VueSimpleAlert from "vue-simple-alert";
import LogIn from '../components/LogIn.vue';
import properties from '../store/modules/properties'

Vue.use(Router);
Vue.use(VueSimpleAlert);
Vue.use(Vuex);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://alpha.realestatealpha.ai';
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.baseURL = 'https://deploy-rea.onrender.com';
// axios.defaults.baseURL = 'http://127.0.0.1:5000';

// const debug = true;
const debug = false;
const api_host = debug ? 'http://127.0.0.1:5000/' : 'https://alpha.realestatealpha.ai';
// const api_host = debug ? 'http://127.0.0.1:5000/' : 'https://deploy-rea.onrender.com';

// Vuex store
const store = new Vuex.Store({
  modules: {
    properties
  },
  state: {
    isAuthenticated: false,
    user: null
  },
  mutations: {
    setAuth(state, auth) {
      state.isAuthenticated = auth;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/api/login', credentials);
        console.log('Login response:', response.data);
        if (response.data.user) {
          commit('setUser', response.data.user);
          commit('setAuth', true);
          console.log("Login response " + response.data);
          console.log('Login response headers:', response.headers);
          // await dispatch('checkSession');
          return true;
        }
        return false;
      } catch (error) {
        console.error('Login error:', error);
        return false;
      }
    },
    async logout({ commit }) {
      try {
        await axios.post(api_host + 'api/logout', {}, { withCredentials: true });
        commit('setAuth', false);
        commit('setUser', null);
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    async checkAuth({ commit }) {
      try {
        // const response = await axios.get(api_host + 'api/check_session');
        const response = await axios.get(api_host + 'api/check_session', { withCredentials: true });
        console.log('Check session response:', response.data);
        commit('setAuth', response.data.logged_in);
        if (response.data.logged_in) {
          commit('setUser', response.data.user);
        }
      } catch (error) {
        console.error('Check session error:', error);
        commit('setAuth', false);
        commit('setUser', null);
      }
    },
    async checkSession({ commit }) {
      try {
        const response = await axios.get('/api/check_session');
        console.log('Check session response:', response.data);
        commit('setAuth', response.data.logged_in);
        commit('setUser', response.data.user);
        return response.data.logged_in;
      } catch (error) {
        console.error('Check session error:', error);
        commit('setAuth', false);
        commit('setUser', null);
        return false;
      }
    }
  }
});

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'LogIn',
      component: LogIn
    },
    // {
    //   path: '/',
    //   name: 'RealEstateAlpha',
    //   component: RealEstateAlpha,
    //   props: true,
    //   meta: { requiresAuth: true}
    // },
    {
      path: '/howto',
      name: 'HowTo',
      component: HowTo,
      meta: { requiresAuth: true }
    },
    {
      path: '/summary',
      name: 'MethodSummary',
      component: MethodSummary,
      meta: { requiresAuth: true }
    },
    {
      path: '/montrealmap',
      name: 'MontrealMap',
      component: MontrealMap,
      meta: { requiresAuth: true }
    },
    {
      path: '/showAlpha',
      name: 'ShowAlpha',
      component: ShowAlpha,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/CalculateAlpha',
      name: 'CalculateAlpha',
      component: CalculatorForm,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/',
      // path: '/property-grid',
      name: 'PropertyGrid',
      component: RealEstateGrid,
      props: true,
      meta: { requiresAuth: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  next();
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!store.state.isAuthenticated || true) {
  //     next('/login');
  //   } else {
  //     next();
  //   }
  // } else {
  //   next();
  // }
});

// const formStore = new Vuex.Store({
//   namespaced: true,
//   state: {
//     formData: {
//       has_renting_unit_details: false,
//       property_details: {
//         source: "",
//         potential_gross_revenues: null,
//         no_of_parkings: 2,
//         parking_type: "Garage",
//         adapted_for_reduced_mobility: false,
//         elevator: false,
//         close_to_body_of_water: false,
//         neighborhood: "",
//         property_type: "",
//         construction_date: null,
//         age_status: "",
//         risk_free_rate: 0.0472,
//         estimates: 4,
//         total_units: 3,
//         residential_units: 3,
//         business_units: 0,
//         fireplace: false,
//         stove: "",
//         heat_pump: false,
//         area: null,
//         has_pool: false,
//         pool_ground_level: "N/A",
//         is_pool_heated: "N/A",
//         pool_location: "N/A",
//         build_style: "",
//         actual_price: null,
//         purchase_price: null,
//         operating_costs: null,
//       },
//       renting_details: {
//         electricity_included: false,
//         heating_included: false,
//         wifi_included: false,
//         parking_included: false,
//         animal_friendly: false,
//         laundry_in_unit: false,
//         air_conditioner_included: false,
//         private_exterior_spaces_included: false,
//         smoking_allowed_included: false,
//         furnished: false,
//         lease_duration_in_months: null,
//         details_from_description: false,
//         water_included: false,
//         tv_included: false,
//         laundry_in_building: false,
//         dishwasher: false,
//         fridge: false,
//         gym: false,
//         concierge: false,
//         security24hrs: false,
//         bicycle_parking: false,
//         storage_space: false,
//       },
//       renting_units: [
//         {
//           no_of_rooms: 0,
//           no_of_bathrooms: 0,
//           area: null,
//         },
//       ],
//     }
//   },
//   mutations: {
//     updateFormData(state, payload) {
//       state.formData = payload;
//     },
//     resetFormData(state) {
//       state.formData = {
//         has_renting_unit_details: false,
//         property_details: {
//           // ... initial state
//         },
//         renting_details: {
//           // ... initial state
//         },
//         renting_units: [
//           {
//             no_of_rooms: 0,
//             no_of_bathrooms: 0,
//             area: null,
//           },
//         ],
//       };
//     }
//   },
//   actions: {
//     saveFormData({ commit }, formData) {
//       commit('updateFormData', formData);
//     }
//   },
//   getters: {
//     getFormData: state => state.formData
//   }
// });


export { router, store};
// export { router, store, formStore };
