// store/modules/properties.js
export default {
    namespaced: true,
    
    state: {
      properties: [],
      isAddingNew: false
    },
    
    mutations: {
      ADD_PROPERTY(state, property) {
        state.properties.push({
          id: Date.now(),
          ...property
        })
      },
      DELETE_PROPERTY(state, propertyId) {
        state.properties = state.properties.filter(p => p.id !== propertyId)
      },
      SET_IS_ADDING_NEW(state, value) {
        state.isAddingNew = value
      }
    },
    
    actions: {
      addProperty({ commit }, property) {
        commit('ADD_PROPERTY', property)
      },
      deleteProperty({ commit }, propertyId) {
        commit('DELETE_PROPERTY', propertyId)
      },
      getProperty({ state }, propertyId) {
        const property = state.properties.find(p => p.id === propertyId);
        return property || null;
      },
      setIsAddingNew({ commit }, value) {
        commit('SET_IS_ADDING_NEW', value)
      }
    },
    
    getters: {
      getAllProperties: state => state.properties,
      isAddingNew: state => state.isAddingNew.ADD_PROPERTY,
      getPropertyById: state => propertyId => {
        return state.properties.find(p => p.id === propertyId) || null;
      }
    }
  }